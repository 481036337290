import { ACCESS_TOKEN_KEY } from '@/modules/Auth';
import Auth from '@/modules/Auth';
import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';
import authorizedRequest from './helpers/authorizedRequest';
import axios from 'axios';
import config from '@/utility/config';
import { getAxiosError, getAxiosErrorMsg } from './helpers';
import unAuthorizedRequest from './helpers/unAuthorizedRequest';
import sessionStorageService from '@/utility/sessionStorageService';
import publicAxiosRequest from './helpers/publicAxiosRequest';
// import protectedAxiosRequest from './helpers/protectedAxiosRequest';
import { timeRequest } from '@/utility/timeRequest';
import { getCookie } from 'cookies-next';

const {
  apiStaticDataPath,
  apiPaymentBasePath,
  communityApiBasePath,
  loginApiRoute,
  isBackendCdnEnabled,
  communityApiBasePathCdn
} = config;

export const enrollToCommunityWaitlistService = async (body) => {
  const uri = `${apiStaticDataPath}/api/v1/communities`;
  const res = await axios(uri, { method: 'POST', data: body });
  const { data, error } = res;

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data, error: false };
};

export const createCommunity = async (payload) => {
  const apiPath = `${apiPaymentBasePath}/api/v1/community-create`;
  const res = await axios(apiPath, {
    method: 'POST',
    data: payload
  });
  const { data, error } = res;
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data, error: false };
};

export const updateCommunity = async (communityId, communityData) => {
  const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}`;
  const res = await adminProtectedAxiosRequest(apiPath, {
    method: 'PUT',
    data: communityData,
    validateStatus: (status) => status <= 500
  });
  const { data, error } = res;

  if (error || data?.error) {
    return { data: null, error: getAxiosErrorMsg(error ?? data) };
  }
  return { data, error: false };
};

export const addAsCommunityManager = async (formData) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/community-manager`;
    const res = await adminProtectedAxiosRequest(apiPath, {
      method: 'POST',
      withCredentials: 'include',
      data: formData
    });
    const data = res.data;

    if (res.error) {
      const errMsg =
        res?.info || res?.message || res?.error || 'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: getAxiosErrorMsg(err) };
  }
};

export const signUp = async (formData) => {
  const apiPath = `${loginApiRoute}/api/v1/sign-up`;
  const res = await axios(apiPath, {
    method: 'POST',
    withCredentials: 'include',
    data: formData
  });
  const { data, error } = res;
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data, error: false };
};

export const signUpCheck = async (email) => {
  const apiPath = `${loginApiRoute}/api/v1/community-manager/signup-check`;

  const res = await publicAxiosRequest(apiPath, {
    method: 'GET',
    params: {
      email
    }
  });
  const { data, error } = res;
  if (error) {
    return { data: null, error };
  }
  return { data, error: false };
};

export const getCommunityDataService = async (
  communitySlug,
  communityCode
) => {
  if (!communitySlug && !communityCode) {
    const error = 'Please have only one option';
    console.error(error);
    return { data: null, error: error };
  }
  if (communitySlug && !communitySlug.includes('/')) {
    const error = 'Community slug is not valid';
    console.error(error);
    return;
  }

  const communitySlugValue =
    communitySlug?.length > 1 ? '/' + communitySlug.split('/')[1] : '';
  const apiPath = `${apiPaymentBasePath}/api/v1/community-data?${
    communitySlugValue ? `communitySlug=${communitySlugValue}` : ''
  }${communityCode ? `communityCode=${communityCode.toUpperCase()}` : ''}`;

  const res = await publicAxiosRequest.get(apiPath);

  const { data, error } = res;

  if (error) {
    return { data, error: getAxiosErrorMsg(error) };
  }

  return { data, error: false };
};

export const adminGetEventById = async ({ communityId, eventId }) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/event-admin/${eventId}`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }

    return { data };
  } catch (e) {
    return { data: null, error: getAxiosErrorMsg(e) };
  }
};

export const getEventBySlug = async (slug) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/event/${slug}`;
    return await publicAxiosRequest.get(apiPath);
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getFolderInfo = async ({
  communityId,
  folderId,
  selectedAmount
}) => {
  try {
    let apiPath = `${communityApiBasePath}/api/v1/communities/member/${communityId}/folder/${folderId}`;
    if (selectedAmount || selectedAmount === 0) {
      apiPath += `?selectedAmount=${selectedAmount}`;
    }
    // const res = await protectedAxiosRequest.get(apiPath);
    const res = await axios.get(apiPath);

    if (res.error) {
      return { error: getAxiosErrorMsg(res.error) };
    }

    return { data: res?.data, error: false };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getEventInfo = async ({
  communityId,
  eventId,
  quantity = 1,
  selectedAmount
}) => {
  try {
    let apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/event/${eventId}?quantity=${quantity}`;
    if (selectedAmount || selectedAmount === 0) {
      apiPath += `&selectedAmount=${selectedAmount}`;
    }
    // const res = await protectedAxiosRequest.get(apiPath);
    const res = await axios.get(apiPath);

    if (res.error) {
      return { error: getAxiosErrorMsg(res.error) };
    }

    return { data: res?.data, error: false };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAuthenticatedEventBySlug = async (slug) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/event/private${slug}`;
    return await adminProtectedAxiosRequest.get(apiPath);
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCommunityDataByCode = async (communityCode) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community-data?communityCode=${communityCode}`;
    return await publicAxiosRequest.get(apiPath);
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCommunityPaths = async () => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community-links`;
    return await publicAxiosRequest.get(apiPath);
  } catch (e) {
    return { data: null, error: e.message };
  }
};

// Not used
export const getCommunityPricingService = async (productId) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/get-community-pricing?product_id=${productId}`;
    return await publicAxiosRequest.get(apiPath);
  } catch (e) {
    return { error: e.message };
  }
};

export const getCommunityPricingServiceV2 = async (communityId) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/subscription-display-prices`;
    const authToken = getCookie(ACCESS_TOKEN_KEY);

    if (authToken) {
      const response = await adminProtectedAxiosRequest.get(apiPath);
      return { data: response?.data, error: null };
    }
    const response = await publicAxiosRequest.get(apiPath);
    return { data: response?.data, error: null };
  } catch (e) {
    return { data: null, error: e.message };
  }
};

export const getCommunityPricingByDiscountCodeService = async (params) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/community-discount-details/`;
    return await publicAxiosRequest.get(path, { params });
  } catch (e) {
    return {
      error: e?.response?.data?.info ?? 'Something went wrong!'
    };
  }
};

export const submitCommunityEnrollmentForm = async (payload) => {
  try {
    const authToken = sessionStorageService.getItem('accessToken') || '';
    const path = `${apiPaymentBasePath}/api/v1/community-enrollment-application`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      data: payload
    };

    const res = await axios(path, options);
    const data = await res.data;

    return data;
  } catch (e) {
    return {
      error: {
        message: getAxiosError(e).error ?? 'Something went wrong!',
        fieldError: e?.response?.data?.info?.field_errors ?? {}
      }
    };
  }
};

export const submitFreeCommunityEnrollment = async (
  payload = {},
  token
) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/community-enrolment-free`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    if (token) {
      options.headers['Authorization'] = 'Bearer ' + token;
    }

    const { data, error } = await authorizedRequest(path, options);

    if (error) {
      throw new Error(error);
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

// Not used
export const getApplicableCollectionsCommunityDataService = async (
  communityCode
) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community-web3-discount_methods?communityCode=${communityCode}`;
    return await publicAxiosRequest.get(apiPath);
  } catch (e) {
    return { error: e.message };
  }
};

export const applyCommunitySignUpDiscount = async (payload) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/community-sign-up-apply-discount/`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const { data, error } = await authorizedRequest(path, options);

    if (error) {
      throw new Error(error);
    }

    return { data, error };
  } catch (e) {
    return { error: e.message };
  }
};

export const startCommunityApplication = async (params) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/start-community-application`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    };
    const { data, error } = await unAuthorizedRequest(apiPath, options);

    if (error) {
      throw new Error(error);
    }

    // if (data?.message !== 'Successfully signed up!') {
    //   const error = new Error(data.message || 'Something went wrong');
    //   error.errorCode = data.errorCode;

    //   throw error;
    // }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: error };
  }
};

export const contactUsApplication = async (params) => {
  try {
    const apiPath = `${apiStaticDataPath}/api/v1/contact-us`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    };
    const { data, error } = await unAuthorizedRequest(apiPath, options);

    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: error };
  }
};

export const getCommunitiesList = async (payload = {}) => {
  const { activeCommunityId } = payload;
  const basePath = isBackendCdnEnabled
    ? communityApiBasePathCdn
    : communityApiBasePath;
  try {
    // const accessToken = Auth.getUserToken();
    const path = `${basePath}/api/v1/communities`;
    const options = {
      method: 'GET',
      ...(activeCommunityId
        ? {
            params: {
              activeCommunityId
            }
          }
        : {})
    };

    const res = await timeRequest(
      () => adminProtectedAxiosRequest.get(path, options),
      'GET: communities'
    );

    const data = res.data;

    return { data, error: false };
  } catch (e) {
    return { data: {}, error: getAxiosError(e).error };
  }
};

export const getLimitAndStats = async (communityId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/limit-and-stats`;
    const options = {
      method: 'GET'
    };

    const res = await adminProtectedAxiosRequest.get(path, options);

    const data = res.data;

    return { data, error: false };
  } catch (e) {
    return getAxiosError(e);
  }
};

export const getResourceBySlug = async (slug) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/resource/${slug}`;
    const accessToken = Auth.getUserToken();
    if (accessToken) {
      return await adminProtectedAxiosRequest(apiPath);
    }
    return await publicAxiosRequest(apiPath);
  } catch (e) {
    return { data: null, error: e };
  }
};

export const inviteMembersByCommunityId = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/invite-members`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const uploadCommunityImage = async (
  communityId,
  { entityType, entityId, base64, mailType }
) => {
  const apiPath = `${communityApiBasePath}/api/v1/image/community/${communityId}`;
  const { data, error } = await adminProtectedAxiosRequest.post(apiPath, {
    entityType,
    entityId,
    base64,
    mailType
  });

  return { data, error };
};

/**
 * Payload = {
    data: [
      {
        email, (mandatory)
        phoneNumber,
        firstName,
        lastName
        },
        ...
    ]
  }
 */
export const inviteMembersInBulk = async (communityId, payload) => {
  const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/bulk-invite-members`;
  const { data, error } = await adminProtectedAxiosRequest.post(
    apiPath,
    payload
  );
  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data, error: null };
};

export const getCommunityAdmins = async (communityId) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin/${communityId}`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const getUserStatus = async (communityId, emails) => {
  try {
    const emailString = Array.isArray(emails)
      ? emails.map((e) => encodeURIComponent(e)).join(',')
      : emails;
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin-status/${communityId}?emails=${emailString}`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const addCommunityManagers = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin/${communityId}`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const removeCommunityManagers = async (communityId, email) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin/${communityId}?email=${email}`;
    const { data, error } =
      await adminProtectedAxiosRequest.delete(apiPath);
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const removeWhatsappOnlyMember = async (params) => {
  try {
    const { communityId, whatsappId } = params;
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/whatsapp/${whatsappId}`;
    const res = await adminProtectedAxiosRequest.delete(apiPath);
    const { data, error } = res;
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const removeCommunityMember = async ({
  communityId,
  // email,
  removalReason,
  learnerId
}) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/community-manager/remove-member`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      {
        communityId,
        // removeMemberEmail: email,
        removalReason,
        learnerId
      }
    );
    if (error) {
      throw new Error(error);
    }

    if (data.error) {
      return {
        data: null,
        error: data.error
      };
    }

    return { data, error: false };
  } catch (error) {
    // return { error: true, data: getAxiosError(error) };
    return { error: getAxiosError(error) };
  }
};

export const doesCommunityMemberHavePaidEvent = async (
  communityId,
  learnerId
) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/${learnerId}/paid-events`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const getAllDiscordChannels = async (communityId) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/getAllChannelsFromDiscord/`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }
    return { ...data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const setDiscordAnnouncementChannel = async (
  communityId,
  payload
) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/setDiscordAnnouncementChannel`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return { ...data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const sendDiscordMessage = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/send-discord-message`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const sendWhatsappMessage = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/communicate/send-whatsapp-template-msg`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const checkMember = async (communityId, token) => {
  const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/isMember`;

  const config = token
    ? {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    : {};

  return await adminProtectedAxiosRequest.get(apiPath, config);
};

export const checkCommunityType = (whatsAppCommunity, requireApproval) => {
  switch (true) {
    case whatsAppCommunity && requireApproval:
      // Both whatsAppCommunity and requireApproval are true
      return 'COMMUNITY_ENROLMENT_APPLICATION_APPROVED_FOR_WHATSAPP';
    case whatsAppCommunity && !requireApproval:
      // whatsAppCommunity is true, requireApproval is false
      return 'COMMUNITY_ENROLMENT_WITHOUT_APPLICATION_FOR_WHATSAPP';
    case !whatsAppCommunity && requireApproval:
      // whatsAppCommunity is false, requireApproval is true
      return 'COMMUNITY_ENROLMENT_APPLICATION_APPROVED';
    default:
      // Both whatsAppCommunity and requireApproval are false
      return 'COMMUNITY_ENROLMENT_WITHOUT_APPLICATION';
  }
};

export const getCommunityWelcomeMessage = async (
  communityId,
  mailType
) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/mail?mailType=${mailType}`;

    const { data, error } = await adminProtectedAxiosRequest.get(path);

    if (error) {
      return { data: null, error: getAxiosErrorMsg(error) };
    }
    return { data: data, error: null };
  } catch (e) {
    console.error(e.message);
    return {};
  }
};

export const updateCommunityWelcomeMessage = async (
  communityId,
  payload
) => {
  try {
    const { mailType } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/mail?mailType=${mailType}`;

    const { data, error } = await adminProtectedAxiosRequest.put(
      path,
      payload
    );

    if (error) {
      return { data: null, error: getAxiosErrorMsg(error) };
    }
    return { data: data, error: null };
  } catch (e) {
    console.error(e.message);
    return {};
  }
};

export const getDefaultCommunityMessage = async (
  communityId,
  mailType
) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/mail?mailType=${mailType}&default=true`;

    const { data, error } = await adminProtectedAxiosRequest.get(path);

    if (error) {
      return { data: null, error: getAxiosErrorMsg(error) };
    }
    return { data: data, error: null };
  } catch (e) {
    console.error(e.message);
    return {};
  }
};

export const sendTestCommunityWelcomeMessage = async (payload) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${payload.communityId}/mail/send`;

    const { data, error } = await adminProtectedAxiosRequest.post(
      path,
      payload
    );

    if (error) {
      return { data: null, error: getAxiosErrorMsg(error) };
    }
    return { data: data, error: null };
  } catch (e) {
    console.error(e.message);
    return {};
  }
};

// New Unified Checkout APIs
export const unifiedCheckoutSignup = async (payload) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/signup`;

    const { data, error } = await adminProtectedAxiosRequest.post(
      path,
      payload
    );

    if (error) {
      return { error: getAxiosErrorMsg(error) };
    }

    return data;
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

export const unifiedCheckoutConfirm = async (payload) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/signup/confirm`;

    const { data, error, errorObj } =
      await adminProtectedAxiosRequest.post(path, payload);

    if (error) {
      return { error: getAxiosErrorMsg(error), errorObj };
    }

    return data;
  } catch (e) {
    return { error: getAxiosErrorMsg(e), errorObj: e };
  }
};

// New Unified Checkout APIs
export const unifiedCheckoutVerifyPayment = async ({
  communityId,
  signupId,
  subscriptionId
}) => {
  try {
    let path = `${communityApiBasePath}/api/v1/communities/${communityId}/subscription/verify-payment?signupId=${signupId}`;
    if (subscriptionId) {
      path += `&subscriptionId=${subscriptionId}`;
    }
    const response = await adminProtectedAxiosRequest.get(path);

    if (response?.error) {
      return {
        ...getAxiosError(response),
        errorObj: response?.errorObj
      };
    }

    return response?.data;
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

// call unifiedCheckoutVerifyPayment 5 times with 800 ms delay between each call till success response is recieved
export const verifyPayment = async (params) => {
  try {
    const { communityId, signupId, subscriptionId } = params;
    let count = 0;
    let response = {};
    while (count < 5) {
      response = await unifiedCheckoutVerifyPayment({
        communityId,
        signupId,
        subscriptionId
      });

      if (response?.status === 418 && response?.error) {
        return { error: response?.error, errorObj: response?.errorObj };
      }
      if (response?.data?.isValid) {
        return response?.data;
      }
      count++;
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
    return response;
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

// New Unified Checkout APIs
export const unifiedCheckoutVerifyOneTimePayment = async ({
  communityId,
  entitySignupId
}) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/entity/verify-payment?entitySignupId=${entitySignupId}`;
    const response = await adminProtectedAxiosRequest.get(path);

    if (response?.error) {
      return {
        ...getAxiosError(response),
        data: response?.data,
        errorObj: response?.errorObj
      };
    }

    return response?.data;
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

// CancelPayment
export const cancelPayment = async ({
  signupToken,
  failureReason,
  failureCode
}) => {
  const path = `${communityApiBasePath}/api/v1/communities/signup/abort`;

  let errorObj = {};

  if (failureReason) {
    errorObj = {
      failureReason,
      failureCode
    };
  }

  return await adminProtectedAxiosRequest.post(path, {
    signupToken,
    ...errorObj
  });
};

// call unifiedCheckoutVerifyPayment 5 times with 800 ms delay between each call till success response is recieved
export const verifyOneTimePayment = async (params) => {
  try {
    const { communityId, entitySignupId } = params;
    let count = 0;
    let response = {};
    while (count < 5) {
      response = await unifiedCheckoutVerifyOneTimePayment({
        communityId,
        entitySignupId
      });

      if (response?.status === 418 && response?.error) {
        return { error: response?.error, errorObj: response?.errorObj };
      }

      if (response?.isValid) {
        return response;
      }

      count++;
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
    return response;
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

export const getEventMessageByEmailType = async (
  communityId,
  mailType,
  eventId
) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/mail?mailType=${mailType}&mailCourseOffer=${eventId}`;

  const { data, error } = await adminProtectedAxiosRequest.get(path);

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const updateEventMessageByEmailType = async (
  communityId,
  payload
) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/mail`;

  const { data, error } = await adminProtectedAxiosRequest.put(
    path,
    payload
  );

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const getDefaultEventMessageByEmailType = async (
  communityId,
  mailType,
  eventId
) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/mail?mailType=${mailType}&default=true&mailCourseOffer=${eventId}`;

  const { data, error } = await adminProtectedAxiosRequest.get(path);

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const sendTestEventMessageByType = async (
  communityId,
  eventId,
  payload
) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/mail/send`;

  const newPayload = {
    ...payload,
    mailCourseOffer: eventId
  };

  const { data, error } = await adminProtectedAxiosRequest.post(
    path,
    newPayload
  );

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }
  return { data: data, error: null };
};

export const getActiveCommunityActivities = async (
  activeCommunityId,
  params
) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${activeCommunityId}/activities`;
    const { data, error } = await adminProtectedAxiosRequest.get(path, {
      params
    });

    if (error) {
      return { data: null, error: getAxiosErrorMsg(error) };
    }
    return { data: data?.data, error: null };
  } catch (e) {
    console.error(e.message);
    return {};
  }
};

export const checkIfProcessingFreePreferenceSet = async (params) => {
  try {
    const { communityId } = params;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/processing-fee-preference`;

    const { data, error } = await adminProtectedAxiosRequest.get(path);

    if (error) {
      return { error: getAxiosErrorMsg(error) };
    }

    return data;
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

export const updateNasioProcessingFeePreference = async (params) => {
  try {
    const { communityId, passOnTakeRate } = params;
    const payload = { passOnTakeRate };
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/processing-fee-preference`;

    const { data, error } = await adminProtectedAxiosRequest.patch(
      path,
      payload
    );

    if (error) {
      return { error: getAxiosErrorMsg(error) };
    }

    return data;
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

export const getDiscountedPriceWithSignUpToken = async (payload) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/signup/discount`;

    const { data, error } = await axios.post(path, payload);

    if (error) {
      return { data: null, error };
    }
    return { data: data?.data, error: null };
  } catch (e) {
    return { error: getAxiosErrorMsg(e) };
  }
};

export const signupFor5DollarCampaign = async (communityId) => {
  const path = `${communityApiBasePath}/api/v1/communities/${communityId}/five-dollar`;
  return await adminProtectedAxiosRequest.post(path);
};
