import Auth from '@/modules/Auth';
import axios from 'axios';
import { getTokensService } from '@/services/community/auth/admin/adminCommunityAuthService';
import { getAxiosErrorMsg } from '.';
import { getLocaleFromCookie } from '@/utility/localization';

const adminProtectedAxiosRequest = axios.create();

const protectedReqConfigMiddleware = (config) => {
  const locale = getLocaleFromCookie();

  if (locale && config?.headers) {
    config.headers['Accept-Language'] = locale;
  }

  // Allow Auth headers to be passed in (for example on login where we fetch token from response manually)
  if (config?.headers?.Authorization) return config;

  const accessToken = `Bearer ${Auth.getUserToken()}`;
  if (Auth.getUserToken()) {
    const argHeaders = config?.headers || {};
    config.headers = {
      Authorization: accessToken,
      ...argHeaders
    };
    return config;
  }

  // Don't run the request if the user token is not available
  return Promise.reject('No access token');
};

const protectedReqErrorMiddleware = (error) => {
  return Promise.reject(error);
};

const protectedResMiddleware = (response) => {
  return response;
};
const protectedResErrorMiddleware = async (resError) => {
  try {
    const status = resError?.response?.status;
    const originalRequest = resError.config;
    let retry = originalRequest?.retry;

    const refreshToken = Auth.getRefreshToken();

    if (status === 403) {
      return { error: 'Not authenticated', status };
    }
    if (!retry) {
      retry = 3;
    }
    if (retry === 0) {
      return { error: 'Request has been failed', status };
    }

    if (refreshToken && status === 401) {
      const payload = { refreshToken, isCommunityAdmin: true };
      const { data, error } = await getTokensService(payload);
      if (error) {
        Auth.deauthenticateUser();
        return { error: 'Not authorized', status };
      }

      const { token, refresh_token } = data;

      Auth.authenticateUser(token, refresh_token);

      if (!token) {
        return { error: 'No access token' };
      }

      originalRequest.headers.Authorization = `Bearer ${token}`;
      retry -= 1;
      return { error: 'Not authorized', status };
    }

    return {
      error: getAxiosErrorMsg(resError),
      errorObj: resError?.response?.data,
      data: {},
      status
    };
  } catch (error) {
    return { error: getAxiosErrorMsg(error), errorObj: error, data: {} };
  }
};

// Request interceptors
adminProtectedAxiosRequest.interceptors.request.use(
  protectedReqConfigMiddleware,
  protectedReqErrorMiddleware
);

// Response interceptors
adminProtectedAxiosRequest.interceptors.response.use(
  protectedResMiddleware,
  protectedResErrorMiddleware
);

export default adminProtectedAxiosRequest;
